*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

img, picture, video, canvas, svg {
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --RS-space-1: 1px;
  --RS-space-2: 2px;
  --RS-space-4: 4px;
  --RS-space-6: 6px;
  --RS-space-8: 8px;
  --RS-space-12: 12px;
  --RS-space-16: 16px;
  --RS-space-24: 24px;
  --RS-space-32: 32px;
  --RS-space-48: 48px;
  --RS-space-64: 64px;
  --RS-space-96: 96px;
  --RS-space-128: 128px;
  --RS-space-192: 192px;
  --RS-space-256: 256px;
  --RS-space-1-n: -1px;
  --RS-space-2-n: -2px;
  --RS-space-4-n: -4px;
  --RS-space-6-n: -6px;
  --RS-space-8-n: -8px;
  --RS-space-12-n: -12px;
  --RS-space-16-n: -16px;
  --RS-space-24-n: -24px;
  --RS-space-32-n: -32px;
  --RS-space-48-n: -48px;
  --RS-space-64-n: -64px;
  --RS-space-96-n: -96px;
  --RS-space-128-n: -128px;
  --RS-space-192-n: -192px;
  --RS-space-256-n: -256px;
  --RS-col-1: 160px;
  --RS-col-2: 320px;
  --RS-col-3: 480px;
  --RS-col-4: 640px;
  --RS-col-5: 800px;
  --RS-col-6: 960px;
  --RS-col-7: 1120px;
  --RS-col-8: 1280px;
  --RS-white-color: #e6e8ec;
  --RS-black-color: #01060e;
  --RS-light-grey-color: #dbdcdd;
  --RS-dark-grey-color: #838485;
  --RS-graphite-color: #161e2b;
  --RS-brand-color: #0a2b4c;
  --RS-accent-color-primary: #195999;
  --RS-accent-color-secondary: #1c207a;
  --RS-brand-color-sample: #7977b9;
  --RS-anim-delay: 50ms;
  --RS-default-transition: cubic-bezier(.39, .575, .565, 1);
  --RS-bounce-transition: cubic-bezier(.175, .885, .32, 1.275);
  --RS-anim-duration-xs: 75ms;
  --RS-anim-duration-sm: .15s;
  --RS-anim-duration-md: .225s;
  --RS-anim-duration-lg: .35s;
  --RS-anim-duration-xlg: .6s;
  --RS-anim-intro-artwork-delay: .75s;
  --RS-anim-full-rotate-duration: 90s;
  --RS-line-height-sm: 1;
  --RS-line-height-md: 1.21;
  --RS-line-height-lg: 1.34;
  --RS-line-height-xlg: 1.55;
  --RS-font-size-h1: clamp(3.125rem, 2.8036rem + 1.4286vw, 4.5rem);
  --RS-font-size-h1-desktop: clamp(4.25rem, 3.7339rem + .6452vw, 4.5rem);
  --RS-font-size-h1-intro: clamp(2rem, 1.5617rem + 1.9481vw, 3.875rem);
  --RS-font-size-h1-intro-desktop: clamp(3.625rem, 3.1089rem + .6452vw, 3.875rem);
  --RS-font-size-h2: clamp(1.3125rem, 1.2468rem + .2922vw, 1.59375rem);
  --RS-font-size-h2-desktop: clamp(1.5rem, 1.2419rem + .3226vw, 1.625rem);
  --RS-font-size-h3: clamp(.75rem, .7135rem + .1623vw, .90625rem);
  --RS-font-size-h3-desktop: clamp(.875rem, .746rem + .1613vw, .9375rem);
  --RS-font-size-top-nav: clamp(.875rem, .8458rem + .1299vw, 1rem);
  --RS-font-size-mobile-nav: clamp(2.625rem, 2.2772rem + 1.7391vw, 3.125rem);
  --RS-font-size-p: clamp(.9375rem, .9083rem + .1299vw, 1.0625rem);
  --RS-font-size-p-desktop: clamp(1.03125rem, .9022rem + .1613vw, 1.09375rem);
  --RS-font-size-label: clamp(.875rem, .8531rem + .0974vw, .96875rem);
  --RS-font-size-label-desktop: clamp(.9375rem, .8085rem + .1613vw, 1rem);
  --RS-font-size-stat: clamp(2.5rem, 2.2516rem + 1.1039vw, 3.5625rem);
  --RS-font-size-disclaimer: clamp(.75rem, .7354rem + .0649vw, .8125rem);
  --RS-font-weight-variable-regular: 375;
  --RS-font-weight-variable-bold: 650;
  --RS-brand-bg-xy: calc(var(--RS-space-24)  - var(--RS-space-8));
  --RS-rotate-firefox-fix: 0deg;
}

@font-face {
  font-family: InstrumentSans-Variable;
  src: url("../InstrumentSans-Variable.e47ce2ea.woff2") format("woff2"), url("../InstrumentSans-Variable.5a7f4308.woff") format("woff"), url("../InstrumentSans-Variable.4473c37d.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}

p, h1, h2, h3, h4, h5, h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-simple-link {
  text-decoration: underline;
  transition: color .35s;
}

.RS-simple-link:hover {
  color: var(--RS-primary-button-color);
}

.RS-link {
  color: inherit;
  padding-bottom: var(--RS-space-2);
  text-decoration: none;
  position: relative;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-black-color);
  position: absolute;
  left: 0;
  transform: scaleX(0);
}

.RS-link:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

@media (min-width: 768px) {
  :is(.RS-link--active:before, .RS-link--active:hover:before) {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap {
  white-space: nowrap;
}

.RS-br-sm {
  display: initial;
}

@media (min-width: 768px) {
  .RS-br-sm {
    display: none;
  }
}

@media (min-width: 480px) {
  .RS-br-md {
    display: none;
  }
}

@media (min-width: 1024px) {
  .RS-br-md {
    display: initial;
  }
}

.RS-br-lg {
  display: none;
}

@media (min-width: 1024px) {
  .RS-br-lg {
    display: initial;
  }
}

.RS-inline-icon {
  min-width: var(--RS-space-16);
  margin: 0 var(--RS-space-4);
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
}

.RS-inline-icon-external-link {
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  font-size: 90%;
  line-height: 90%;
}

.RS-inline-icon-external-link svg path {
  fill: var(--RS-primary-button-color);
}

.RS-font-weight-bold {
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-regular {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-font-style-italic {
  display: inline-flex;
  transform: skewX(-8deg);
}

html {
  font-size: 100%;
}

body {
  font-family: InstrumentSans-Variable, system-ui, sans-serif;
  font-size: 16px;
  font-weight: var(--RS-font-weight-variable-regular);
  color: var(--RS-black-color);
  letter-spacing: 0;
  background-color: var(--RS-white-color);
  line-height: 1;
  transition: none;
  overflow-x: hidden;
  overflow-y: auto;
}

body > main {
  width: 100%;
  min-height: 1px;
  overflow: hidden;
}

a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

@media screen and (min--moz-device-pixel-ratio: 0) {
  :root {
    --RS-rotate-firefox-fix: .03deg;
  }

  svg, path {
    outline: 1px solid #fff0;
  }

  html {
    scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-light-grey-color);
    background: var(--RS-the-red-color);
  }
}

::selection {
  color: var(--RS-light-grey-color);
  background: var(--RS-brand-color);
}

body::-webkit-scrollbar {
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-light-grey-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-dark-grey-color);
  border-radius: 4px;
}

[role="button"] {
  cursor: pointer;
}

svg path, svg g {
  transform-box: fill-box;
  transform-origin: center;
}

img, video {
  width: 100%;
  height: auto;
  outline: 1px solid #0000;
}

picture, img {
  border-radius: inherit;
}

ul {
  list-style: none;
}

.RS-photo--square img {
  aspect-ratio: 1 / 1;
}

.RS-photo--vertical img {
  aspect-ratio: .665 / 1;
}

.RS-photo--horizontal img {
  aspect-ratio: 1.5 / 1;
}

.RS-section {
  width: 100%;
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-96);
}

@media (min-width: 768px) {
  .RS-section {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-128);
  }
}

@media (min-width: 1024px) {
  .RS-section {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-128);
  }
}

.RS-section--inverted {
  background-color: var(--RS-brand-color);
  color: var(--RS-white-color);
}

.RS-container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

.RS-content {
  width: 100%;
}

.RS-content--no-overflow {
  overflow: hidden;
}

.RS-content__figure {
  width: 100%;
  height: 100%;
  min-height: 20vh;
  border-radius: var(--RS-space-6);
  background-color: var(--RS-light-grey-color);
}

.RS-content--intro {
  position: absolute;
  bottom: 0;
  left: 0;
}

.RS-animation {
  opacity: .01;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-xlg);
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
  transition-property: transform, opacity;
}

.RS-animation__slow {
  transition-duration: var(--RS-anim-duration-xlg);
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 8px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -13px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-right {
  transform: translate3d(89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-left {
  transform: translate3d(-89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-top {
  transform: translate3d(0, 55px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, -55px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-looped-animation {
  backface-visibility: hidden;
  will-change: transform;
  animation-play-state: paused !important;
}

.RS-looped-animation--running {
  animation-play-state: running !important;
}

.RS-top-bar {
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  backface-visibility: hidden;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  position: fixed;
  inset: 0 0 auto;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .RS-top-bar {
    padding: var(--RS-space-24) var(--RS-space-32);
  }
}

.RS-top-bar:before {
  content: "";
  width: inherit;
  height: 100%;
  z-index: -1;
  background: var(--RS-white-color);
  opacity: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(#000, #0000 100%);
  mask-image: linear-gradient(#000, #0000 100%);
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  max-width: var(--RS-col-8);
  height: 32px;
  flex-flow: row;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.RS-top-bar__brand {
  z-index: 1;
}

.RS-top-bar__logo {
  width: var(--RS-space-64);
  height: calc(var(--RS-space-32)  + var(--RS-space-8));
  margin: 0 var(--RS-space-8) 0 0;
}

.RS-top-bar__nav {
  height: 100vh;
  width: 100vw;
  background: var(--RS-white-color);
  top: var(--RS-space-24-n);
  left: var(--RS-space-24-n);
  justify-content: center;
  align-items: center;
  gap: var(--RS-space-32);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: var(--RS-font-size-mobile-nav);
  flex-direction: column;
  display: none;
  position: fixed;
  transform: translateX(100%);
}

@media (min-width: 768px) {
  .RS-top-bar__nav {
    height: inherit;
    justify-content: flex-end;
    gap: calc(var(--RS-space-32)  + var(--RS-space-4));
    width: unset;
    font-size: inherit;
    background: unset;
    top: unset;
    left: unset;
    font-size: var(--RS-font-size-top-nav);
    flex-direction: row;
    margin-left: auto;
    display: flex;
    position: relative;
    transform: none;
  }
}

.RS-top-bar__nav--displayed {
  display: flex;
}

.RS-top-bar__nav--opened {
  transform: translateX(0%);
}

.RS-top-bar__nav > .RS-link {
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  font-weight: var(--RS-font-weight-variable-bold);
  letter-spacing: .25px;
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link {
    opacity: 1;
    font-weight: var(--RS-font-weight-variable-bold);
    transform: none;
  }
}

.RS-top-bar__nav > .RS-link--visible {
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-link:first-of-type {
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:first-of-type {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(2) {
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(2) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(3) {
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(3) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(4) {
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(4) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(5) {
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(5) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(6) {
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(6) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(7) {
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(7) {
    transition-delay: unset;
  }
}

.RS-top-bar__mobile-nav-icon {
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  margin-left: auto;
  display: block;
}

@media (min-width: 768px) {
  .RS-top-bar__mobile-nav-icon {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span {
  width: calc(var(--RS-space-32)  + var(--RS-space-1));
  height: var(--RS-space-4);
  background-color: var(--RS-black-color);
  margin: var(--RS-space-6) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
  display: block;
}

.RS-top-bar__mobile-nav-icon--active span:first-of-type {
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-6)  + 1px));
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2) {
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-6-n)  - 1px));
}

.RS-top-bar__detail {
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--RS-light-grey-color);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.RS-top-bar__detail-inner {
  max-width: calc(var(--RS-col-8)  + var(--RS-space-32) * 2);
  height: 100%;
  margin: 0 auto;
  display: none;
  position: relative;
}

@media (min-width: 768px) {
  .RS-top-bar__detail-inner {
    display: block;
  }
}

.RS-top-bar__detail-inner > span {
  width: calc(var(--RS-space-16)  - var(--RS-space-1));
  height: calc(var(--RS-space-16)  - var(--RS-space-1));
  box-shadow: inset 0 0 0 1px var(--RS-light-grey-color);
  top: calc((var(--RS-space-16-n)  - var(--RS-space-1)) / 2);
  border-radius: 50%;
  display: block;
  position: absolute;
}

.RS-top-bar__detail-inner > span:first-of-type {
  left: calc(var(--RS-space-16-n) / 2 - var(--RS-space-2-n)  - var(--RS-space-2-n));
}

.RS-top-bar__detail-inner > span:nth-of-type(2) {
  right: calc(var(--RS-space-16-n) / 2 - var(--RS-space-2)  - var(--RS-space-1-n));
}

.RS-button {
  width: 100%;
  color: var(--RS-white-color);
  border-radius: var(--RS-space-4);
  background-color: var(--RS-brand-color);
  padding: 0 var(--RS-space-16);
  -webkit-tap-highlight-color: transparent;
  font-weight: var(--RS-font-weight-variable-bold);
  padding: var(--RS-space-16) var(--RS-space-48);
  outline: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .RS-button {
    width: auto;
    min-width: calc(var(--RS-space-128)  + var(--RS-space-32));
  }
}

.RS-button:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--RS-accent-color-primary);
  pointer-events: none;
  opacity: 0;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-sm);
  transform-origin: bottom;
  transform: scale(.13) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
  transition-property: transform, opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-button--inverted:before, .RS-button--ghost:before {
  background-color: var(--RS-light-grey-color);
}

.RS-button.RS-button--active:before, .RS-button:hover:before {
  opacity: .21;
  transform: scale(1.2);
}

.RS-button--inverted {
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active, .RS-button--inverted:hover {
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}

.RS-button--small {
  padding: var(--RS-space-12) var(--RS-space-24);
  min-width: unset;
}

.RS-button--ghost {
  background-color: unset;
  color: var(--RS-black-color);
  border: 1px solid var(--RS-light-grey-color);
}

.RS-button--ghost.RS-button--active, .RS-button--ghost:hover {
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: clamp(1.25rem, 1.1994rem + .2532vw, 1.5rem);
  line-height: 1;
  transform: translate3d(0, 0, 0);
}

.RS-button.RS-button--nav {
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 var(--RS-space-4) 0;
}

@media (min-width: 768px) {
  .RS-button.RS-button--nav {
    background-color: var(--RS-brand-color);
    color: var(--RS-white-color);
    padding: var(--RS-space-12);
    opacity: 1;
  }
}

.RS-button__accent {
  pointer-events: none;
  opacity: 0;
  border-radius: 50%;
  display: block;
  position: absolute;
  -webkit-mask-image: radial-gradient(#000, #0000 67%);
  mask-image: radial-gradient(#000, #0000 67%);
}

@media (min-width: 768px) {
  .RS-button__accent {
    display: block;
  }
}

.RS-button--nav .RS-button__accent {
  display: none;
}

@media (min-width: 768px) {
  .RS-button--nav .RS-button__accent {
    display: block;
  }
}

.RS-button__accent--primary {
  width: 50%;
  height: 100%;
  background: var(--RS-accent-color-primary);
  top: -50%;
  left: -12.5%;
}

.RS-button__accent--secondary {
  width: 67%;
  height: 100%;
  background: var(--RS-accent-color-secondary);
  bottom: -50%;
  right: -10%;
}

.RS-button__accent--tetriary {
  width: var(--RS-space-64);
  height: var(--RS-space-64);
  bottom: var(--RS-space-12-n);
  right: var(--RS-space-16);
  background: var(--RS-light-grey-color);
}

.RS-button__accent--anim-right {
  animation: 8s linear infinite animButtonAccentRight;
}

@keyframes animButtonAccentRight {
  0% {
    opacity: 0;
  }

  13%, 89% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(125%, 15%, 0)scaleY(1.5)scaleX(1.13)rotateX(4deg)rotateY(8deg);
  }
}

.RS-button__accent--anim-left {
  animation: 10s linear 2s infinite animButtonAccentLeft;
}

@keyframes animButtonAccentLeft {
  0% {
    opacity: 0;
  }

  13%, 89% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(-100%, -15%, 0)scaleY(1.5)scaleX(1.13)rotateX(4deg)rotateY(8deg);
  }
}

.RS-button__accent--anim-pulse {
  animation: 12s linear 4s infinite animButtonAccentPulse;
}

@keyframes animButtonAccentPulse {
  0%, 100% {
    opacity: 0;
    transform: translate3d(0%, 0%, 0)skewX(15deg)skewY(10deg);
  }

  50% {
    opacity: .5;
    transform: translate3d(0%, 0%, 0)skewX(5deg)skewY(10deg)scaleY(.67)scaleX(.67);
  }
}

.RS-button__content {
  z-index: 2;
  gap: var(--RS-space-8);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.RS-button__arrow {
  gap: var(--RS-space-2);
  transition: transform var(--RS-anim-duration-sm) var(--RS-default-transition);
  flex-direction: column;
  display: flex;
}

.RS-button__arrow span {
  width: var(--RS-space-8);
  height: var(--RS-space-2);
  background-color: var(--RS-white-color);
  transition: transform var(--RS-anim-duration-sm) var(--RS-default-transition);
  border-radius: var(--RS-space-4);
  display: block;
}

.RS-button__arrow--inverted span {
  background-color: var(--RS-black-color);
}

.RS-button__arrow span:first-of-type {
  transform: translateY(var(--RS-space-2)) rotateZ(40deg);
}

.RS-button__arrow span:nth-of-type(3) {
  transform: translateY(var(--RS-space-2-n)) rotateZ(-40deg);
}

.RS-button__arrow span:nth-of-type(2) {
  opacity: 0;
  transform: scaleX(0) translateX(calc(-50% - var(--RS-space-2)));
  transform-origin: 100%;
}

.RS-button.RS-button--active .RS-button__arrow, .RS-button.RS-button:hover .RS-button__arrow {
  transform: translateX(var(--RS-space-2));
}

.RS-list {
  font-weight: var(--RS-font-weight-variable-bold);
  padding: var(--RS-space-32) 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.RS-list li {
  padding: 0 0 var(--RS-space-16) 0;
  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);
}

@media (min-width: 1024px) {
  .RS-list li {
    font-size: var(--RS-font-size-p-desktop);
  }
}

.RS-list--tiles {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: var(--RS-space-32);
  flex-direction: row;
}

.RS-list--tiles li {
  padding: var(--RS-space-16);
  width: 100%;
  border-radius: var(--RS-space-6);
  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);
  background-color: var(--RS-light-grey-color);
}

@media (min-width: 1024px) {
  .RS-list--tiles li {
    font-size: var(--RS-font-size-p-desktop);
  }
}

.RS-section-marquee--hero {
  width: 100vw;
  height: auto;
  padding: unset;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-lg) var(--RS-default-transition);
  align-items: flex-end;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.RS-section-marquee--hero--hidden {
  opacity: 0;
  mix-blend-mode: luminosity;
}

.RS-marquee {
  height: var(--RS-space-48);
}

@media (min-width: 1024px) {
  .RS-marquee {
    padding: var(--RS-space-64) 0;
  }
}

.RS-marquee-wrapper {
  padding: var(--RS-space-48) 0;
  width: 100%;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .RS-marquee-wrapper {
    padding: var(--RS-space-64) 0;
    width: 100%;
  }
}

.RS-marquee-wrapper--hero {
  backface-visibility: hidden;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  padding: 0;
}

@media (min-width: 1024px) {
  .RS-marquee-wrapper--hero {
    padding: var(--RS-space-8) 0;
  }
}

.RS-marquee-wrapper--hero:before {
  content: "";
  width: inherit;
  height: 100%;
  z-index: -1;
  background: var(--RS-white-color);
  opacity: 1;
  transition: opacity var(--RS-anim-duration-xs) var(--RS-default-transition);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(0deg, #0000 0%, #00000080 34% 50%, #0000 100%);
  mask-image: linear-gradient(0deg, #0000 0%, #00000080 34% 50%, #0000 100%);
}

@media (min-width: 1024px) {
  .RS-marquee-wrapper--hero:before {
    -webkit-mask-image: linear-gradient(0deg, #0000 0%, #00000080 21% 50%, #0000 100%);
    mask-image: linear-gradient(0deg, #0000 0%, #00000080 21% 50%, #0000 100%);
  }
}

.RS-section-marquee--hero--hidden .RS-marquee-wrapper--hero:before {
  opacity: 0;
}

.RS-marquee__item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.RS-marquee__item svg {
  height: var(--RS-space-24);
  max-width: var(--RS-space-96);
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .RS-marquee__item svg {
    height: var(--RS-space-32);
    max-width: var(--RS-space-128);
  }
}

.RS-marquee__item--lg-h svg {
  height: var(--RS-space-32);
}

@media (min-width: 1024px) {
  .RS-marquee__item--lg-h svg {
    height: var(--RS-space-48);
  }
}

.RS-marquee__item--xl-h svg {
  height: var(--RS-space-48);
}

@media (min-width: 1024px) {
  .RS-marquee__item--xl-h svg {
    height: var(--RS-space-64);
  }
}

.RS-marquee__item--sm-h svg {
  height: var(--RS-space-16);
}

@media (min-width: 1024px) {
  .RS-marquee__item--sm-h svg {
    height: var(--RS-space-24);
  }
}

.RS-marquee__item--lg-w svg {
  max-width: calc(var(--RS-space-96)  + var(--RS-space-8));
}

@media (min-width: 1024px) {
  .RS-marquee__item--lg-w svg {
    max-width: calc(var(--RS-space-128)  + var(--RS-space-32));
  }
}

.keen-slider__slide {
  min-width: 100%;
  max-width: 100%;
  transform: translate3d(0, 0, 0);
}

.RS-background {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
}

.RS-background__detail {
  height: 100%;
  width: 1px;
  background: var(--RS-light-grey-color);
  display: none;
  position: absolute;
  top: 0;
}

@media (min-width: 768px) {
  .RS-background__detail {
    display: block;
  }
}

.RS-background__detail--left {
  left: var(--RS-space-32-n);
}

.RS-background__detail--right {
  right: var(--RS-space-32-n);
}

.RS-header--centered {
  text-align: center;
}

@media (min-width: 768px) {
  .RS-header--wide {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced {
  margin: var(--RS-space-48) auto;
}

@media (min-width: 768px) {
  .RS-header--spaced {
    margin: var(--RS-space-32) auto var(--RS-space-64);
  }
}

@media (min-width: 768px) {
  .RS-header--social {
    max-width: var(--RS-col-6);
  }
}

@media (min-width: 768px) {
  .RS-header--intro {
    padding-top: 10vh;
  }
}

.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: .15em;
  display: inline-flex;
}

@media (min-width: 1280px) {
  .RS-header__headline {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 1280px) {
  .RS-header__description {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-header__description + .RS-header__description {
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description + .RS-header__description--secondary {
  margin: var(--RS-space-48) 0 0 0;
}

.RS-header__description + .RS-button {
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 1280px) {
  .RS-header__description--secondary {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-header__headline + .RS-header__description--secondary {
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
  position: relative;
}

.RS-header__description-link:hover {
  opacity: 1;
  box-shadow: inset 0 0 #0000;
}

.RS-header__description-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  background-color: var(--RS-pure-white-color);
  transform-origin: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
  display: block;
  position: absolute;
  left: 0;
}

.RS-header__description-link:hover:before {
  transform-origin: 100%;
  background-color: var(--RS-primary-button-color);
  transform: scaleX(0);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;
}

@media (min-width: 768px) {
  .RS-header .RS-header__button {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: calc(var(--RS-anim-duration-xlg)  + var(--RS-anim-duration-lg));
}

.RS-header .RS-header__link, .RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-secondary-icon {
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg {
  height: calc(var(--RS-space-24)  + var(--RS-space-2));
  width: calc(var(--RS-space-24)  + var(--RS-space-2));
}

@media (min-width: 768px) {
  .RS-header-secondary-icon > svg {
    height: calc(var(--RS-space-32)  + var(--RS-space-2));
    width: calc(var(--RS-space-32)  + var(--RS-space-2));
  }
}

.RS-article {
  width: 100%;
  max-width: var(--RS-col-6);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.RS-article--centered {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.RS-article--block {
  display: block;
}

.RS-article--cols {
  gap: var(--RS-space-24);
  align-items: center;
}

@media (min-width: 1024px) {
  .RS-article--cols {
    gap: var(--RS-space-48);
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .RS-article--reverse {
    flex-direction: row-reverse;
  }
}

.RS-article--wide {
  max-width: 100%;
}

@media (min-width: 1024px) {
  .RS-article--wide {
    max-width: var(--RS-col-8);
  }
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);
}

@media (min-width: 768px) {
  .RS-article > svg {
    margin: 0 0 var(--RS-space-64);
  }
}

@media (min-width: 1024px) {
  .RS-article > svg {
    margin: 0;
  }
}

.RS-article + .RS-article {
  margin-top: var(--RS-space-96);
}

.RS-intro {
  z-index: 0;
  padding: 12vh var(--RS-space-24) 4vh;
  align-items: flex-end;
  display: flex;
  position: relative;
}

@media (max-height: 414px) and (max-width: 1024px) {
  .RS-intro {
    height: auto;
    min-height: 100vh;
  }
}

@media (min-width: 1024px) {
  .RS-intro {
    min-height: unset;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro__container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.RS-intro__kv {
  width: 100%;
  max-width: var(--RS-col-6);
  height: calc(var(--RS-col-6) * .625);
  border-radius: var(--RS-space-6);
  margin-top: var(--RS-space-32);
  z-index: -1;
  position: relative;
}

.RS-intro__kv-inner {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--RS-light-grey-color);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 22px 22px 29px #00000014, 1px 0 #00000008;
}

.RS-intro__kv-bg {
  width: 100vh;
  height: 100vh;
  border-radius: inherit;
  pointer-events: none;
  opacity: .21;
  border-radius: 50%;
  position: absolute;
  -webkit-mask-image: radial-gradient(#000, #0000 67%);
  mask-image: radial-gradient(#000, #0000 67%);
}

@keyframes animIntroKv {
  50% {
    opacity: .5;
    transform: rotateZ(180deg)scale(1.2);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.RS-intro__kv-bg--left {
  background-color: var(--RS-accent-color-primary);
  animation: 8s linear infinite animIntroKv;
  top: -25vh;
  left: -37.5%;
}

.RS-intro__kv-bg--right {
  background-color: var(--RS-accent-color-secondary);
  animation: 10s linear infinite animIntroKv;
  top: -12.5vh;
  right: -37.5%;
}

.RS-intro__kv-bg--mid {
  width: 25vh;
  height: 10vh;
  background-color: var(--RS-accent-color-primary);
  opacity: .13;
  animation-delay: 4s;
  top: -5vh;
  left: calc(50% - 12.5vh);
}

.RS-intro__word-slider {
  height: 100%;
  padding-left: var(--RS-space-8);
  display: inline-flex;
}

.RS-intro__word-slider-inner {
  padding: 0 var(--RS-space-24) var(--RS-space-12) var(--RS-space-24);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.RS-intro__word-slider-inner:before {
  content: "";
  width: 100%;
  height: 100%;
  border: var(--RS-space-4) solid var(--RS-brand-color);
  border-radius: var(--RS-space-16);
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(0deg, #000 34%, #0000 34%);
  mask-image: linear-gradient(0deg, #000 34%, #0000 34%);
}

.RS-intro__word-slider-inner > span:not(:first-of-type) {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-intro__word-slider-inner > span {
  opacity: 0;
  transform: translateY(var(--RS-space-16-n));
  color: var(--RS-brand-color);
  background: linear-gradient(120deg, var(--RS-accent-color-secondary) 0%, var(--RS-accent-color-primary) 50% 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: 18s linear infinite animIntroWordSlide;
}

.RS-intro__word-slider-inner > span:nth-of-type(2) {
  animation-delay: 6s;
}

.RS-intro__word-slider-inner > span:nth-of-type(3) {
  animation-delay: 12s;
}

@keyframes animIntroWordSlide {
  0%, 5% {
    opacity: 0;
    transform: translateY(var(--RS-space-16-n));
  }

  6.25%, 33.5% {
    opacity: 1;
    transform: translateY(0);
  }

  35%, 100% {
    opacity: 0;
    transform: translateY(var(--RS-space-16));
  }
}

.RS-footer {
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-32);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 768px) {
  .RS-footer {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-footer__container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-footer__copyright {
  font-size: var(--RS-font-size-disclaimer);
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-footer__navigation {
  margin: var(--RS-space-8) 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .RS-footer__navigation {
    margin: var(--RS-space-48) 0;
    flex-direction: row;
  }
}

.RS-footer-col {
  font-size: var(--RS-font-size-label);
  line-height: var(--RS-line-height-xlg);
  margin-bottom: var(--RS-space-12);
  gap: var(--RS-space-12);
  display: flex;
}

@media (min-width: 768px) {
  .RS-footer-col {
    margin-bottom: 0;
    display: block;
  }
}

@media (min-width: 1280px) {
  .RS-footer-col {
    font-size: var(--RS-font-size-label-desktop);
  }
}

.RS-footer__container > .RS-footer-col {
  margin: 0;
}

.RS-footer-col .RS-link svg {
  margin-left: var(--RS-space-4);
}

.RS-cookie-bar {
  left: var(--RS-space-8);
  bottom: var(--RS-space-8);
  z-index: 666;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, var(--RS-space-32), 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: calc(100% - var(--RS-space-8) * 2);
  position: fixed;
}

@media (min-width: 480px) {
  .RS-cookie-bar {
    width: auto;
    left: var(--RS-space-32);
    bottom: var(--RS-space-32);
  }
}

.RS-cookie-bar.RS-cookie-bar--visible {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.RS-cookie-bar__box {
  padding: var(--RS-space-12) var(--RS-space-16);
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
  border-radius: var(--RS-space-6);
  box-shadow: 22px 22px 29px #00000014, 1px 0 #00000008;
}

.RS-cookie-bar__content {
  align-items: center;
  gap: var(--RS-space-12);
  padding: 0 var(--RS-space-8);
  flex-direction: column;
  display: flex;
}

@media (min-width: 480px) {
  .RS-cookie-bar__content {
    gap: var(--RS-space-24);
    flex-direction: row;
  }
}

.RS-cookie-bar__title {
  margin: 0 0 var(--RS-space-8);
  font-size: 32px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .RS-cookie-bar__title {
    font-size: 40px;
    line-height: 48px;
  }
}

.RS-cookie-bar__title-icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: currentColor;
  display: inline-block;
  position: relative;
  top: -1px;
}

@media (min-width: 768px) {
  .RS-cookie-bar__title-icon {
    width: 40px;
    height: 48px;
    top: -2px;
  }
}

.RS-cookie-bar__actions {
  gap: var(--RS-space-16);
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.e1d5e2ff.css.map */
