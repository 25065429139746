/* Background */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
  /* z-index: 667; */
}

.RS-background__detail{
  position: absolute;
  height: 100%;
  width: 1px;
  background: var(--RS-light-grey-color);
  top: 0;
  display: none;

  @media (--md-viewport) {
    display: block;
  }
}

.RS-background__detail--left{
  left: var(--RS-space-32-n);
}

.RS-background__detail--right{
  right: var(--RS-space-32-n);
}
