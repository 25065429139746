/* Intro */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-intro {
  position: relative;
  z-index: 0;
  padding: 12vh var(--RS-space-24) 4vh;
  display: flex;
  align-items: flex-end;
  /* height: 90vh; */

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--lg-viewport) {
    /* height: 100vh; */
    min-height: unset;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;

  @media (--lg-viewport) {
    /* flex-direction: row-reverse; */
  }

}

.RS-intro__kv{
  width: 100%;
  max-width: var(--RS-col-6);
  height: calc(var(--RS-col-6)*0.625);
  border-radius: var(--RS-space-6);
  margin-top: var(--RS-space-32);
  position: relative;
  z-index: -1;


  @media (--lg-viewport) {
    /* flex-direction: row-reverse; */
  }
}

.RS-intro__kv-inner{
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--RS-light-grey-color);
  box-shadow: 22px 22px 29px 0px rgba(0,0,0,0.08), 1px 0px 0px 0px rgba(0,0,0,0.03);
}

.RS-intro__kv-bg{
  width: 100vh;
  height: 100vh;
  border-radius: inherit;
  position: absolute;
  border-radius: 50%;
  mask-image: radial-gradient(rgba(0, 0, 0, 1), transparent 67%);
  pointer-events: none;
  opacity: .21;
}

@keyframes animIntroKv {
  50%{
    opacity: .5;
    transform: rotateZ(180deg) scale(1.2);
  }
  100%{
    transform: rotateZ(360deg);
  }
}

.RS-intro__kv-bg--left{
  left: -37.5%;
  top: -25vh;
  background-color: var(--RS-accent-color-primary); 
  animation: animIntroKv 8s linear infinite;
}

.RS-intro__kv-bg--right{
  right: -37.5%;
  top: -12.5vh;
  background-color: var(--RS-accent-color-secondary); 
  animation-delay: 2s;
  animation: animIntroKv 10s linear infinite;
}

.RS-intro__kv-bg--mid{
  width: 25vh;
  height: 10vh;
  top: -5vh;
  left: calc(50% - 12.5vh);
  background-color:var(--RS-accent-color-primary); 
  opacity:.13;
  animation-delay: 4s;
}

.RS-intro__word-slider{
  display: inline-flex;
  height: 100%;
  padding-left: var(--RS-space-8);
}

.RS-intro__word-slider-inner{
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: var(--RS-space-512); */
  /* min-height: 88px; */
  justify-content: center;
  align-items: center;
  /* border-bottom: 2px solid var(--RS-black-color); */
  overflow: hidden;
  padding: 0 var(--RS-space-24) var(--RS-space-12) var(--RS-space-24);
}

.RS-intro__word-slider-inner:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* background: red; */
  border: var(--RS-space-4) solid var(--RS-brand-color); 
  border-radius: var(--RS-space-16);
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 34%, transparent 34%);

}

.RS-intro__word-slider-inner > span:not(:first-of-type){
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.RS-intro__word-slider-inner > span{
  opacity: 0;
  transform: translateY(var(--RS-space-16-n));
  animation: animIntroWordSlide 18s linear infinite;
  color: var(--RS-brand-color); 
  background: linear-gradient(120deg, var(--RS-accent-color-secondary) 0%, var(--RS-accent-color-primary) 50% 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.RS-intro__word-slider-inner > span:nth-of-type(2){
  animation-delay: 6s;
}

.RS-intro__word-slider-inner > span:nth-of-type(3){
  animation-delay: 12s;
}

@keyframes animIntroWordSlide {
  0%,5%{
    opacity: 0;
    transform: translateY(var(--RS-space-16-n));
  }
  6.25%,33.5%{
    opacity: 1;
    transform: translateY(0);
  }
  35%,100%{
    opacity: 0;
    transform: translateY(var(--RS-space-16));
  }
}
