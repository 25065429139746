
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--RS-white-color);
  border-radius: var(--RS-space-4);
  /* box-shadow: inset 0 0 0 2px var(--RS-accent-color-secondary); */
  /* border: 2px solid var(--RS-accent-color-secondary); */
  background-color: var(--RS-brand-color);
  padding: 0 var(--RS-space-16);
  /* height: 48px; */
  -webkit-tap-highlight-color: transparent;
  outline: none;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: var(--RS-space-16) var(--RS-space-48);
  overflow: hidden;

  @media (--md-viewport) {
    width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32));
  }
}

.RS-button:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--RS-accent-color-primary);
  pointer-events: none;
  opacity: 0;
  /* z-index: -1; */
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-sm);
  transition-property: transform, opacity;
  transform-origin: bottom;
  transform: scale(.13) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
}

.RS-button--inverted:before{
  background-color: var(--RS-light-grey-color);
}

.RS-button--ghost:before{
  background-color: var(--RS-light-grey-color);
}

.RS-button.RS-button--active:before,
.RS-button:hover:before {
  /* color: var(--RS-white-color); */
  opacity: .21;
  transform: scale(1.2);
  /* background-color: var(--RS-accent-color-secondary); */
}


.RS-button--inverted{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active,
.RS-button--inverted:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}


.RS-button--small{
  padding: var(--RS-space-12) var(--RS-space-24);
  min-width: unset;
}

.RS-button--ghost{
  background-color: unset;
  color: var(--RS-black-color);
  /* border: 1px solid var(--RS-dark-grey-color); */
  border: 1px solid var(--RS-light-grey-color);
}

.RS-button--ghost.RS-button--active,
.RS-button--ghost:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}


/* .RS-button > span {
  position: relative;
  display: inline-block;
  line-height: var(--RS-line-height-md);
  font-size: clamp(1.125rem, 1.1123rem + 0.0633vw, 1.1875rem);
} */



.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  font-size: 24px;
  font-size: clamp(1.25rem, 1.1994rem + 0.2532vw, 1.5rem);
  line-height: 1;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}


.RS-button.RS-button--nav{
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 var(--RS-space-4) 0;

  @media (--md-viewport) {
    background-color: var(--RS-brand-color);
    color: var(--RS-white-color);
    padding: var(--RS-space-12);
    opacity: 1;
  }
}


.RS-button__accent{
  position: absolute;
  border-radius: 50%;
  /* z-index: -1; */
  mask-image: radial-gradient(rgba(0, 0, 0, 1), transparent 67%);
  pointer-events: none;
  display: block;
  opacity: 0;

  @media (--md-viewport) {
    display: block;
  }
}

.RS-button--nav .RS-button__accent{
  display: none;

  @media (--md-viewport) {
    display: block;
  }
}

.RS-button__accent--primary{
  width: 50%;
  height: 100%;
  top: -50%;
  left: -12.5%;
  background: var(--RS-accent-color-primary);
}

.RS-button__accent--secondary{
  width: 67%;
  height: 100%;
  bottom: -50%;
  right: -10%;
  background: var(--RS-accent-color-secondary);
}

.RS-button__accent--tetriary{
  width: var(--RS-space-64);
  height: var(--RS-space-64);
  bottom: var(--RS-space-12-n);
  right: var(--RS-space-16);
  background: var(--RS-light-grey-color);
}

.RS-button__accent--anim-right{
  animation: animButtonAccentRight 8s linear infinite;
}

@keyframes animButtonAccentRight {
  0%{
    opacity: 0;
  }
  13%,89%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(125%, 15%, 0) scaleY(1.5) scaleX(1.13) rotateX(4deg) rotateY(8deg);
  }
}

.RS-button__accent--anim-left{
  animation: animButtonAccentLeft 10s linear infinite;
  animation-delay: 2s;
}

@keyframes animButtonAccentLeft {
  0%{
    opacity: 0;
  }
  13%,89%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, -15%, 0) scaleY(1.5) scaleX(1.13) rotateX(4deg) rotateY(8deg);
  }
}

.RS-button__accent--anim-pulse{
  animation: animButtonAccentPulse 12s linear infinite;
  animation-delay: 4s;
}

@keyframes animButtonAccentPulse {
  0%,100%{
    opacity: 0;
    transform: translate3d(0%, 0%, 0) skewX(15deg) skewY(10deg);
  }
  50% {
    opacity: .5;
    transform: translate3d(0%, 0%, 0) skewX(+5deg) skewY(+10deg) scaleY(.67) scaleX(.67);
  }
}

.RS-button.RS-button--active .RS-button__accent,
.RS-button.RS-button:hover .RS-button__accent{
  /* opacity: 0.2; */
}

.RS-button__content{
  z-index: 2;
  display: flex;
  gap: var(--RS-space-8);
  flex-direction: row;
  align-items: center;
}

.RS-button__arrow{
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-2);
  transition: transform var(--RS-anim-duration-sm) var(--RS-default-transition);
}


.RS-button__arrow span{
  display: block;
  width: var(--RS-space-8);
  height: var(--RS-space-2);
  background-color: var(--RS-white-color);
  transition: transform var(--RS-anim-duration-sm) var(--RS-default-transition);
  border-radius: var(--RS-space-4);
}

.RS-button__arrow--inverted span{
  background-color: var(--RS-black-color);
}


.RS-button__arrow span:nth-of-type(1){
  transform: translateY(var(--RS-space-2)) rotateZ(40deg);
}

.RS-button__arrow span:nth-of-type(3){
  transform: translateY(var(--RS-space-2-n)) rotateZ(-40deg);
}

.RS-button__arrow span:nth-of-type(2){
  opacity: 0;
  transform: scaleX(0) translateX(calc(-50% - var(--RS-space-2)));
  transform-origin: right;
}

.RS-button.RS-button--active .RS-button__arrow,
.RS-button.RS-button:hover .RS-button__arrow{
  /* transform: translateX(var(--RS-space-4)); */
  transform: translateX(var(--RS-space-2));
}

.RS-button.RS-button--active .RS-button__arrow span:nth-of-type(2),
.RS-button.RS-button:hover .RS-button__arrow span:nth-of-type(2){
  /* transform: scaleX(1) translateX(calc(-50% + var(--RS-space-1)));
  opacity: 1; */
}

/* .RS-button.RS-button--active .RS-button__arrow span:nth-of-type(1),
.RS-button.RS-button:hover .RS-button__arrow span:nth-of-type(1){
  transform: translateY(1.5px) rotateZ(42.5deg);
}

.RS-button.RS-button--active .RS-button__arrow span:nth-of-type(3),
.RS-button.RS-button:hover .RS-button__arrow span:nth-of-type(3){
  transform: translateY(-1.5px) rotateZ(-42.5deg);
} */