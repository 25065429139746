/* Cookie Bar */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-cookie-bar {
  position: fixed;
  left: var(--RS-space-8);
  bottom: var(--RS-space-8);
  z-index: 666;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, var(--RS-space-32), 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: calc(100% - var(--RS-space-8)*2);

  @media (--sm-viewport) {
    width: auto;
    left: var(--RS-space-32);
    bottom: var(--RS-space-32);
  }
}

.RS-cookie-bar.RS-cookie-bar--visible {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.RS-cookie-bar__box {
  /* max-width: 465px; */
    padding: var(--RS-space-12) var(--RS-space-16);
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
  box-shadow: 22px 22px 29px 0px rgba(0,0,0,0.08), 1px 0px 0px 0px rgba(0,0,0,0.03);
  border-radius: var(--RS-space-6);

  @media (--md-viewport) {
  }
}

.RS-cookie-bar__content {
  display: flex;
  /* flex-flow: column nowrap; */
  align-items: center;
  flex-direction: column;
  gap: var(--RS-space-12);
  padding: 0 var(--RS-space-8);

  @media (--sm-viewport) {
    flex-direction: row;
    gap: var(--RS-space-24);
  }
}

.RS-cookie-bar__title {
  margin: 0 0 var(--RS-space-8);
  font-size: 32px;
  line-height: 40px;

  @media (--md-viewport) {
    font-size: 40px;
    line-height: 48px;
  }
}

.RS-cookie-bar__title-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: currentColor;

  @media (--md-viewport) {
    top: -2px;
    width: 40px;
    height: 48px;
  }
}

.RS-cookie-bar__description {

}

.RS-cookie-bar__actions {
  display: flex;
  gap: var(--RS-space-16);
  align-items: center;
}

