/* Animations */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-animation {
  opacity: 0.01;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-xlg);
  transition-property: transform, opacity;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
}

.RS-animation__slow{
  transition-duration: var(--RS-anim-duration-xlg);
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 8px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -13px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}


.RS-animation__image-to-right {
  transform: translate3d(89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-left {
  transform: translate3d(-89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-top {
  transform: translate3d(0, 55px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, -55px, 0) rotate(var(--RS-rotate-firefox-fix));
}

/* .RS-animation__kv-inner {
  transform: rotateX(45deg) rotate(var(--RS-rotate-firefox-fix));
  opacity: 1;
} */


.RS-looped-animation{
  animation-play-state: paused!important;
  backface-visibility: hidden;
  will-change: transform;
}

.RS-looped-animation--running{
  animation-play-state: running!important;
}