/* Marquee */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);


.RS-section-marquee--hero{
  position: absolute;
  width: 100vw;
  height: auto;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  padding: unset;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-lg) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}

.RS-section-marquee--hero--hidden{
  /* transform: translate3d(0, var(--RS-space-32), 0); */
  opacity: 0;
  mix-blend-mode: luminosity;
}

.RS-marquee{
  /* width: 100%; */
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  height: var(--RS-space-48);
  /* animation: loopMarquee 6s linear infinite;
  transform: translateX(200%); */

  @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
  }
}
/* 
.RS-marquee:nth-of-type(2){
  position: absolute;
  left: 50%;
  top: 0;
} */

/* @keyframes loopMarquee {
  100% {
    transform: translate3d(-100%, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  }
} */

.RS-marquee__item {
  /* width: calc(100%/7); */
}


.RS-marquee{
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  /* height: var(--RS-space-48); */
  /* animation: loopMarquee 6s linear infinite; */
  /* transform: translateX(200%); */
  /* padding: var(--RS-space-64) 0;
  position: relative; */
  /* left: calc(100% / 3); */
  /* left: calc(100% / -6); */
  /* left: unset; */
  /* right: calc(100% / -14); */
  /* left: 0; */

  /* @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
  } */
}



/* @keyframes loopMarquee {
  100% {
    transform: translate3d(-200%, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  }
} */

/* .RS-marquee__item {
  width: calc(100% / 6);
  position: absolute;
} */




.RS-marquee-wrapper{
  padding: var(--RS-space-48) 0;
  width: 100%;
  cursor: pointer;

  @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
    width: 100%;
  }
}

.RS-marquee-wrapper--hero{
  padding: 0;
  backface-visibility: hidden;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  @media (--lg-viewport) {
    padding: var(--RS-space-8) 0;
  }
}



.RS-marquee-wrapper--hero:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: 100%;
  z-index: -1;
  background: var(--RS-white-color);
  opacity: 1;
  /* mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1), transparent 100%); */
  /* mask-image: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 1) 0%, transparent 100%); */
  mask-image: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, .5) 34% 50%, transparent 100%);

  transition: opacity var(--RS-anim-duration-xs) var(--RS-default-transition);


  @media (--lg-viewport) {
    /* mask-image: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 1) 5%, transparent 100%); */
    mask-image: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, .5) 21% 50%, transparent 100%);
  }
}

.RS-section-marquee--hero--hidden .RS-marquee-wrapper--hero:before{
  opacity: 0;
}

.RS-marquee__item{
  display: flex;
  justify-content: center;
  align-items: center;
}

.RS-marquee__item svg{
  height: var(--RS-space-24);
  max-width: var(--RS-space-96);
  margin: 0 auto;

  @media (--lg-viewport) {
    height: var(--RS-space-32);
    max-width: var(--RS-space-128);
  }
}

.RS-marquee__item--lg-h svg{
  height: var(--RS-space-32);

  @media (--lg-viewport) {
    height: var(--RS-space-48);
  }
}

.RS-marquee__item--xl-h svg{
  height: var(--RS-space-48);

  @media (--lg-viewport) {
    height: var(--RS-space-64);
  }
}

.RS-marquee__item--sm-h svg{
  height: var(--RS-space-16);

  @media (--lg-viewport) {
    height: var(--RS-space-24);
  }
}

.RS-marquee__item--lg-w svg{
  max-width: calc(var(--RS-space-96) + var(--RS-space-8));

  @media (--lg-viewport) {
    max-width: calc(var(--RS-space-128) + var(--RS-space-32));
  }
}

.keen-slider__slide {
  min-width: calc(100% + 0px);
  max-width: calc(100% + 0px);
  transform: translate3d(0, 0, 0);
}